import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import routes from "../../routes";
import {connect} from "react-redux";
import {getServicesByCategory} from "./OrderService";
import FormLoader from "../../ubold/commons/FormLoader";
import {Link} from "react-router-dom";
import feather from "feather-icons";
import Giftcards from "./services/Giftcards";
import Crypto from "./services/Crypto";
import Cashincome from "./services/Cashincome";
import {LazyLoadImage} from "react-lazy-load-image-component";
import GridServices from "./services/GridServices";
import OrderServicesWithCatLoading from "../../components/loadings/OrderServicesWithCatLoading";

const bread=[
    {
        'title': 'لیست خدمات',
        'link':routes.orders_service,
        'active': false
    },
    {
        'title': 'ثبت سفارش جدید',
        'active': true
    }
];
const button= (
    <Link className={'btn btn-primary primary btn-sm'} to={routes.orders_service}>
        <i className='d-inline-block ml-1' dangerouslySetInnerHTML={{__html: feather.icons['arrow-right'].toSvg()}}/>
        بازگشت
    </Link>
);

class ServiceItems extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            loading:true,
            services: [],
            cat: null,
            crypto_base_exchanger:null,
            crypto_active_exchangers:null
        }

        this.props.dispatch(updatePageTitle(
            'ثبت سفارش جدید',
            bread,button
        ));
    }

    componentDidMount() {
        getServicesByCategory(this.props.match.params.id).then(res=>{
            this.setState({
                loading:false,
                services: res.data.services,
                cat: res.data.cat,
                crypto_base_exchanger:res.data.crypto_base_exchanger,
                crypto_active_exchangers:res.data.crypto_active_exchangers,
                cash_cat : res.data.cashincome_cat
            });

            this.props.dispatch(updatePageTitle(
                 res.data.cat.name,
                bread,button
            ));
        })
    }


    render() {
        const {cat,services,cash_cat}=this.state;
        return (
           <div className='mt-4'>
               {
                   this.state.loading?
                       <OrderServicesWithCatLoading/>
                       :
                       <React.Fragment>
                           <div className="services-items-box page" >
                               {cat.is_gift?
                                   <Giftcards cat={cat} services={services} />
                                   :
                                   (
                                       cat.is_crypto?
                                           <Crypto />
                                           :
                                           cat.is_cash?
                                               <Cashincome cat={cat} services={services} />
                                               :<>
                                                   {
                                                       cat.is_grid?
                                                           <GridServices cat={cat} services={services} />
                                                           :
                                                           <div className="row justify-content-center">
                                                               <div className="col-xl-6 col-lg-8">
                                                                   {
                                                                       services.map((service,index)=>(
                                                                           <Link key={index} to={service.is_cash?(routes.cash_new + service.cash_id+"?cat="+cash_cat.id):(routes.orders_new+ service.id)}>
                                                                               <div  className=" card card-body card-order-item  d-flex flex-row   align-items-center">
                                                                                   <LazyLoadImage
                                                                                       style={{borderRadius:'10px'}}
                                                                                       alt={service.title}
                                                                                       height={40}
                                                                                       src={service.icon?service.icon:service.service_icon} // use normal <img> attributes as props
                                                                                   />

                                                                                   <h5 className="title">{service.title}</h5>
                                                                               </div>
                                                                           </Link>
                                                                       ))
                                                                   }
                                                               </div>
                                                           </div>
                                                   }
                                               </>

                                   )
                               }
                           </div>

                       </React.Fragment>
               }
           </div>
        )
    }

}

export default connect()(ServiceItems);

