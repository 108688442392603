import React from 'react';
import {updatePageTitle} from "../../actions/breadcrumbs";
import {calculate,  getOrderForm} from "../../modules/orders/OrderService";
import Form from "../../ubold/commons/form";
import Joi from "joi-browser";
import $ from "jquery";
import routes from "../../routes";
import {Link} from "react-router-dom";
import feather from "feather-icons";
import {formatMoney} from "../../services/helpers";

class OrderForm extends Form {

    no_meta_filed=[
        'amount' ,
        'fk_currency_id' ,
        'fk_plan_id' ,
        'attachments' ,
        'user_description',
        'rules',
        'qty'
    ];

    attach_fields=[
        'passport',
        'ghabz'
    ];

    constructor(props) {
        super(props);

        this.state = {
            loadingForm: true,
            service: null,
            currencies: null,
            data: {},
            errors: {},
            loading: false,
            calcLoading: false,
            timer: null,
            hasAttachField:false,
            redirect: false,
            maxGiftQty:5,
            modal:false,
            hasFormAttachment:false,
            selectedCountryID:0,
            planss:[]
        };
    }

    componentDidMount() {
        getOrderForm(this.props.match.params.id).then(res => {
            let sh = {};
            let data = {};
            const service=res.data.service;

            res.data.fields.map(f => {
                if (f.type === 'account') {
                    sh['account'] = Joi.label('اطلاعات حساب کاربری');
                    sh['user_account_username'] = Joi.label('نام کاربری');
                    sh['user_account_password'] = Joi.label('رمز عبور');
                }
                else if (f.type === 'protection_code') {
                    sh['hpcode'] = Joi.label('فیلد کد حفاظتی');
                    data['hpcode'] = 'خیر';
                    sh['pcode'] = Joi.label('کد حفاظتی');
                }
                else {
                    if (this.attach_fields.indexOf(f.name) < 0) {
                        this.setState({hasAttachField:true});
                        if(f.pattern)
                            sh[f.name] = Joi.string().regex(new RegExp(f.pattern)).required().label(f.label);
                        else
                            sh[f.name] = Joi.any().required().label(f.label);
                    }
                    else{
                        sh[f.name] = Joi.any().meta({ swaggerType: 'file' })
                            .description('file to upload').required().label(f.label);
                    }

                }

                data[f.name] = f.default_value?f.default_value:'';

                if(f.type==='file'){
                    this.setState({hasFormAttachment:true})
                }

                return data;
            });


            if(service.plans.length > 1)
                data['fk_plan_id']=service.plans[0].id;
            else
                data['fk_plan_id']=0;

            data['amount']=0;

            this.setState({
                data: data,
                maxGiftQty: res.data.max_gift_qty
            });


            if (service.currency.length > 0) {
                const data = this.state.data;
                data.fk_currency_id = service.currency[0].id;
                let ot = [];
                service.currency.map(cr => {
                    ot.push({
                        'name': cr.name,
                        '_id': cr.id,
                    });

                    return ot;
                });

                this.setState({data, currenciesOptions: ot});

                sh['fk_currency_id'] = Joi.required()
                    .label('نوع ارز');
            }

            if (service.plans.length > 0) {
                const data = this.state.data;
                data.fk_plan_id = service.plans[0].id;
                let ot = [];
                service.plans.map(pl => {
                    ot.push({
                        'name':(pl.fa_name),
                        '_id': pl.id,
                        'country':pl.country
                    });
                    return ot;
                });

                this.setState({data, plansOptions: ot,planss:ot});
            }

            sh['rules'] = Joi.required()
                .label('شرایط و قوانین');

            sh['user_description'] = Joi.label('توضیحات کاربر');
            sh['attachments'] = Joi.label(' فایل پیوست');
            sh['fk_plan_id'] = Joi.label('پلن');



            this.setState({
                loadingForm: false,
                fields: res.data.fields,
                service: service,
                currencies: service.currency,
                plans:service.plans
            });

            this.schema = sh;

            this.props.dispatch(updatePageTitle(
                'سفارش ' + this.state.service.title,
                [
                    {
                        'title': this.state.service.cat.name,
                        'link': routes.service_items+this.state.service.cat.id
                    },
                    {
                        'title': 'ثبت سفارش جدید',
                        'active': true
                    }
                ],(
                    <Link className={'btn btn-primary primary btn-sm'} to={routes.orders_service}>
                        <i className='d-inline-block ml-1' dangerouslySetInnerHTML={{__html: feather.icons['arrow-right'].toSvg()}}/>
                        بازگشت
                    </Link>
                )
            ));

            // if(service.plans)
            //     this.orderCalculate();

        });
    }

    findCountry = (plan,countries) =>{
        if(countries.length>0)
            return countries.filter((item)=> parseInt(plan.country)=== parseInt(item.id))[0];

        return false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.service && prevState.service !== this.state.service) {

            if(this.state.service.plans.length && this.state.service.country.length===0)
                this.orderCalculate();

            if (this.state.service.country.length && prevState.service?.country !== this.state.service.country) {
                this.handelSelectItemsOptions(this.state.service.country[0]);
            }
        }
    }

    otherProcess = (input) => {
        if (input.name === 'fk_plan_id' || input.name === 'fk_currency_id') {
            this.orderCalculate();
        }
    }

    handleKeyUp = (input) => {
        const cl = this;
        if (input.target.name === 'amount') {
            clearTimeout(this.state.timer);

            const t = setTimeout(function () {
                cl.orderCalculate();
            }, 1000);

            this.setState({
                timer: t
            })
        }
    }

    orderCalculate = () => {

        this.setState({
            calcLoading: true
        });

        $("#result").hide();

        var data = this.state.data;

        calculate(this.state.service.id, data).then(res => {
            $("#result").html(res.data.view).show();

            this.setState({
                calcLoading: false
            });
        });

    }

    handelSelectItemsOptions=(cat)=> {
        this.setState({selectedCountryID:cat});

        var result;
        if (this.state.service.country.length) {
            result = this.state.planss.filter((item) => parseInt(item.country) === parseInt(cat.id));
        } else {
            result= this.state.planss;
        }

        var d= this.state.data;
        d['fk_plan_id']=result[0]._id;

        this.setState({plansOptions:result},()=>{
            this.setState({data:d},()=>{
                this.orderCalculate();
            })

        })
    }

}

export default OrderForm;
