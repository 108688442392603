import React from 'react';
import PriceItem from "./PriceItem";
import ReactPaginate from 'react-paginate';

class DigitalPrices extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            page: 1,
            current_page: 1,
            pageCount: Math.ceil(this.props.services.length/20),
            coins: this.props.services,
            coinsBackup:this.props.services,
            from: 0,
            per_page: 20,
            to: 20,
            search:'',
            paginate_data:[]
        }
    }

    handlePageClick= (page)=> {
        const p=page.selected + 1;
        const from=((this.state.per_page * p)  - this.state.per_page);
        const to=(this.state.per_page * p);

        this.setState({
            current_page:p,
            from,
            to,
            paginate_data:this.state.coins.slice(from, to)
        });
        // this.sliceData();
    }

    sliceData= ()=> {
        var coins=this.state.coins;
        this.setState({
            paginate_data:coins.slice(this.state.from, this.state.to)
        });
    }

    componentDidMount() {
        this.sliceData();
    }

    handleSearch=(input)=> {

        var coins=this.state.coinsBackup;
        if(input.target.value){
            coins = coins.filter(coin => {
                let regex = new RegExp(input.target.value.toUpperCase(), 'i'); 	// Create a regex object (a constructor).
                if(regex.test(coin.symbol))
                    return true;
                else if(regex.test(coin.name))
                    return true;
                else if(regex.test(coin.title))
                    return true;
                else
                    return false;
            } );
        }

        else
            coins=this.state.coinsBackup;

        const p= 1;
        const from=((this.state.per_page * p)  - this.state.per_page);
        const to=(this.state.per_page * p);

        this.setState({
            services:coins,
            paginate_data:coins.slice(this.state.from, this.state.to),
            pageCount: Math.ceil(coins.length/20),
            from,
            to,
            current_page:p,
            search:input.target.value
        });
        // this.sliceData()

    }

    render() {

        return (
         <>
             <div style={{background: 'rgb(0 0 0 / 2%)',borderRadius:'px px  0 0'}}>
                 <div className="row justify-content-between align-items-center">
                     <div className="col-md-5 ">
                         <div className="p-3" >
                             <div className='d-flex align-items-center'>
                                <div>
                                    تعداد ارزهای دیجیتال در کافه ارز :
                                </div>
                                 <strong className='mr-2'>{this.props.total}</strong>
                             </div>
                         </div>
                     </div>

                     <div className="col-md-4">
                         <div className="p-3 ltr">
                             <input  placeholder="search" type="text" className="" value={this.state.search} name="search"  onChange={this.handleSearch} />
                         </div>
                     </div>

                 </div>
             </div>

             <div className="header">

                 <div className="dg-name">
                     نام ارز
                 </div>
                 <div className="dg-price">
                     آخرین قیمت <span className="d-none d-md-inline-block">(دلاری)</span>
                 </div>
                 <div className="dg-price ">
                     نرخ تومانی
                 </div>
                 <div className=" dg-price d-none d-md-block ">
                     معامله
                 </div>
                 <div className="d-none d-lg-block dg-100">
                     تغییرات
                 </div>

             </div>
             <div>
                 {
                     this.state.paginate_data
                         .map(service =>(
                         <PriceItem cat={this.props.cat} key={'dig'+service.service_id} coin={service}/>
                     ))
                 }
             </div>

            <div className="p-3">
                <ReactPaginate
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"active "}
                    breakLabel="..."
                    nextLabel=" >"
                    onPageChange={this.handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={this.state.pageCount}
                    previousLabel="< "
                    renderOnZeroPageCount={null}
                />
            </div>
         </>
        )
    }

}


export default DigitalPrices;
